/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fonts.com
*/
html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  font-family: Helvetica Neue;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664070/ec6281a0-c9c4-4477-a360-156acd53093f.woff2') format('woff2'),
    url('../assets/fonts/5664070/11066b40-10f7-4123-ba58-d9cbf5e89ceb.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664077/2707a251-2d32-4bb6-a3c4-87114ba2365f.woff2') format('woff2'),
    url('../assets/fonts/5664077/40f50724-486b-4e7b-9366-237e06eabfc8.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664081/7d63ccf8-e0ae-4dee-ad4d-bbc798aa5803.woff2') format('woff2'),
    url('../assets/fonts/5664081/b2c1327f-ab3d-4230-93d7-eee8596e1498.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664067/2a7e8f89-c0b2-4334-9c34-7a2078d2b959.woff2') format('woff2'),
    url('../assets/fonts/5664067/32aad9d8-5fec-4b9d-ad53-4cf7a5b53698.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664085/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2') format('woff2'),
    url('../assets/fonts/5664085/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664089/5e4f385b-17ff-4d27-a63a-9ee28546c9a8.woff2') format('woff2'),
    url('../assets/fonts/5664089/116cde47-4a07-44a5-9fac-cbdcc1f14f79.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664093/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2') format('woff2'),
    url('../assets/fonts/5664093/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664098/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.woff2') format('woff2'),
    url('../assets/fonts/5664098/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664103/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2') format('woff2'),
    url('../assets/fonts/5664103/7802e576-2ffa-4f22-a409-534355fbea79.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664107/de68be2a-5d0e-4b8d-b3eb-940f75503e2a.woff2') format('woff2'),
    url('../assets/fonts/5664107/31029e78-79a0-4940-b82d-2e3c238e1355.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664150/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2') format('woff2'),
    url('../assets/fonts/5664150/7b415a05-784a-4a4c-8c94-67e9288312f5.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664111/13ab58b4-b5ba-4c95-afde-ab2608fbbbd9.woff2') format('woff2'),
    url('../assets/fonts/5664111/5018b5b5-c821-4653-bc74-d0b11d735f1a.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664115/7e42a406-9133-48c0-a705-4264ac520b43.woff2') format('woff2'),
    url('../assets/fonts/5664115/837750e9-3227-455d-a04e-dc76764aefcf.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664119/0acba88f-0de4-4d43-81fd-920d7427f665.woff2') format('woff2'),
    url('../assets/fonts/5664119/713c9c40-9cbd-4276-819e-d0efaf5d3923.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664121/fc4fb6ca-f981-4115-b882-c78e9f08be52.woff2') format('woff2'),
    url('../assets/fonts/5664121/6ed03453-f512-45ba-84bf-fe4ea45d5e6a.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/5664128/995add04-59cc-41ea-abd2-4712eaddf2a8.woff2') format('woff2'),
    url('../assets/fonts/5664128/7090e465-f6bf-4664-8b5a-d877a6915d87.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'GilRoy-Bold';
  src: url('../assets/fonts/gilroy/Gilroy-Bold.ttf');
}
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter/Inter.ttf');
}