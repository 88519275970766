/* Main color date picker single */
.MuiPickersDay-current {
  color: #5770c6 !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #5770c6 !important;
}
.MuiPickersDay-daySelected {
  background-color: #5770c6 !important;
  color: #fff !important;
}

/* date picker range */
.DatePickerRange-day {
  margin: 0 !important;
  width: 40 !important;
  border-radius: 0 !important;
}

.DatePickerRange-day-beginCap {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.DatePickerRange-day-endCap {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.MuiOutlinedInput-input {
  padding: 18.5px 20px !important;
}
.MuiIconButton-root{
  width: 32px;
  height: 32px;
  padding: 9px!important;
}

.table-row-kols-padding{
  padding: 14px 8px 15px 0!important;
}
.table-header-kols-padding{
  padding: 20px 8px 15px 0px!important;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

